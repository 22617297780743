<template>
  <b-card id="seccion-content">
    <b-card-header class="header-df">
      <b-col
        cols="12"
        md="6"
        class="pl-0"
      >
        <b-media vertical-align="center">
          <template #aside>
            <feather-icon
              size="36"
              icon="InfoIcon"
            />
          </template>
          <span class="font-weight-bold d-block text-nowrap">
            Accesos al Recurso
          </span>
          <small class="text-muted">SECCIÓN II</small>
        </b-media>
      </b-col>
      <div class="float-right">
        <b-button-group size="sm">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="secondary"
            :to="{ name: 'rt-plataforma-info-atractivo' }"
          >
            <feather-icon
              class="mr-50"
              icon="ChevronLeftIcon"
            />
            <span class="align-middle">Atrás</span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            @click="saveForm"
          >
            <feather-icon
              class="mr-50"
              icon="SaveIcon"
            />
            <span class="align-middle">Guardar</span>
          </b-button>
        </b-button-group>
      </div>
    </b-card-header>
    <b-card-body>
      <b-form class="pb-2 pl-0 pr-0 pt-1">
        <b-overlay
          :show="isBusy"
          rounded="sm"
        >
          <b-row v-if="isReady">
            <b-col
              cols="12"
              md="4"
            >
              <h6 class="text-dark mb-1">
                <i :class="accesosRecurso.find(item => item.idClasificacion === '15').iconoClasificacion" />
                <span class="align-middle ml-25">
                  {{ accesosRecurso.find(item => item.idClasificacion === '15').nombreClasificacion }}
                </span>
              </h6>
              <b-form-group>
                <b-form-checkbox
                  v-for="acceso in accesosRecurso.filter(item => item.idClasificacion === '15')"
                  :key="`check-acceso-${acceso.idClasificacionAtractivo}`"
                  v-model="acceso.activoClasificacionAtractivo"
                  class="mb-1 custom-control-primary"
                >
                  {{ acceso.descripcionClasificacionAtractivo }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <h6 class="text-dark mb-1">
                <i :class="accesosRecurso.find(item => item.idClasificacion === '16').iconoClasificacion" />
                <span class="align-middle ml-25">
                  {{ accesosRecurso.find(item => item.idClasificacion === '16').nombreClasificacion }}
                </span>
              </h6>
              <b-form-group>
                <b-form-checkbox
                  v-for="acceso in accesosRecurso.filter(item => item.idClasificacion === '16')"
                  :key="`check-acceso-${acceso.idClasificacionAtractivo}`"
                  v-model="acceso.activoClasificacionAtractivo"
                  class="mb-1 custom-control-primary"
                >
                  {{ acceso.descripcionClasificacionAtractivo }}
                </b-form-checkbox>
              </b-form-group>
              <h6 class="text-dark mb-1">
                <i :class="accesosRecurso.find(item => item.idClasificacion === '17').iconoClasificacion" />
                <span class="align-middle ml-25">
                  {{ accesosRecurso.find(item => item.idClasificacion === '17').nombreClasificacion }}
                </span>
              </h6>
              <b-form-group>
                <b-form-checkbox
                  v-for="acceso in accesosRecurso.filter(item => item.idClasificacion === '17')"
                  :key="`check-acceso-${acceso.idClasificacionAtractivo}`"
                  v-model="acceso.activoClasificacionAtractivo"
                  class="mb-1 custom-control-primary"
                >
                  {{ acceso.descripcionClasificacionAtractivo }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <h6 class="text-dark mb-1">
                <i :class="accesosRecurso.find(item => item.idClasificacion === '18').iconoClasificacion" />
                <span class="align-middle ml-25">
                  {{ accesosRecurso.find(item => item.idClasificacion === '18').nombreClasificacion }}
                </span>
              </h6>
              <b-form-group>
                <b-form-checkbox
                  v-for="acceso in accesosRecurso.filter(item => item.idClasificacion === '18')"
                  :key="`check-acceso-${acceso.idClasificacionAtractivo}`"
                  v-model="acceso.activoClasificacionAtractivo"
                  class="mb-1 custom-control-primary"
                >
                  {{ acceso.descripcionClasificacionAtractivo }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
        </b-overlay>
      </b-form>
    </b-card-body>
  </b-card>
</template>

<script>
import store from '@/store'
import {
  BOverlay, BButton, BCol, BForm, BCard, BCardBody, BCardHeader, BMedia, BButtonGroup, BRow, BFormGroup, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useNotify } from '@/helpers/toast'
import { ref } from '@vue/composition-api'

export default {
  directives: {
    Ripple,
  },
  components: {
    BOverlay,
    BButton,
    BCol,
    BForm,
    BCard,
    BCardBody,
    BCardHeader,
    BMedia,
    BButtonGroup,
    BRow,
    BFormCheckbox,
    BFormGroup,
  },
  props: {
    idAtractivo: {
      type: Number,
      required: true,
      default: null,
    },
  },
  data() {
    return {
    }
  },
  setup(props, context) {
    const { notify } = useNotify(context)
    const isBusy = ref(false)
    const isReady = ref(false)
    const accesosRecurso = ref([])

    const getAccesos = async () => {
      isBusy.value = true
      isReady.value = false
      await store
        .dispatch('plataforma/ATRACTIVO_GET_ACCESOS', {
          idAtractivo: props.idAtractivo,
        })
        .then(response => {
          if (response) {
            accesosRecurso.value = response
          }
        })
        .catch(error => {
          let errorMessage = 'Intentelo en otro momento.'
          if (error.response) {
            errorMessage = error.response.data.message
          }
          notify('Ha ocurrido un error', errorMessage, 'danger')
        })
      isBusy.value = false
      isReady.value = true
    }

    const saveForm = async () => {
      isBusy.value = true
      const formData = ref({})
      formData.value.idAtractivo = props.idAtractivo
      formData.value.seccion = 'acceso_recurso'
      formData.value.idsClasificacion = accesosRecurso.value.filter(item => item.activoClasificacionAtractivo).map(obj => obj.idClasificacionAtractivo).join(',')

      await store.dispatch('plataforma/ATRACTIVO_CHECK_DETALLES', formData.value)
        .then(async response => {
          notify('Operación Exitosa', response.message, 'success')
        })
        .catch(error => {
          let errorMessage = 'Intentelo en otro momento.'
          if (error.response) {
            errorMessage = error.response.data.message
          }
          notify('Ha ocurrido un error', errorMessage, 'danger')
        })
      isBusy.value = false
    }

    const loadData = async () => {
      await getAccesos()
    }

    loadData()
    return {
      accesosRecurso,
      isBusy,
      isReady,
      saveForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.header-df {
  background-color: rgba(34, 41, 47, 0.03) !important;
  border-bottom: 1px solid rgba(34, 41, 47, 0.125) !important;
}

.nav-tabs {
  background: #f6f6f6 !important;
}

.actions-bar {
  background: #f6f6f6 !important;
  justify-content: end;
}

.form-group {
  margin-bottom: 0.5rem !important;
}

#seccion-content.card>.card-body {
  padding-top: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

#seccion-content {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
