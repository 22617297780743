<template>
  <div>
    <section id="faq-tabs">
      <b-tabs
        vertical
        content-class="col-12 col-md-8 col-lg-9"
        pills
        nav-wrapper-class="faq-navigation col-md-4 col-lg-3 col-12"
        nav-class="nav-left"
      >
        <!-- payment tab -->
        <b-tab active>
          <!-- title -->
          <template #title>
            <feather-icon
              icon="InfoIcon"
              size="18"
              class="mr-1"
            />
            <div class="chat-info flex-grow-1">
              <span class="font-weight-bold">SECCIÓN I</span>
              <p
                class="card-text text-truncate mb-0"
                style="font-size: 0.8rem;"
              >
                Datos Generales
              </p>
            </div>
          </template>
          <seccion-i
            v-if="isLoad"
            :data-edit="dataEdit"
            @error-data="showError"
          />
        </b-tab>
        <!--/ payment tab -->

        <!-- delivery tab -->
        <b-tab>
          <!-- title -->
          <template #title>
            <font-awesome-icon
              icon="car"
              size="1x"
              class="mr-1"
            />
            <div class="chat-info flex-grow-1">
              <span class="font-weight-bold">SECCIÓN II</span>
              <p
                class="card-text text-truncate mb-0"
                style="font-size: 0.8rem;"
              >
                Accesos al Recurso
              </p>
            </div>
          </template>
          <seccion-i-i
            v-if="isLoad"
            :id-atractivo="parseInt(idAtractivo)"
          />
        </b-tab>
        <!--/ delivery tab -->

        <!-- cancellation and return -->
        <b-tab>
          <!-- title -->
          <template #title>
            <feather-icon
              icon="UserCheckIcon"
              size="18"
              class="mr-1"
            />
            <div class="chat-info flex-grow-1">
              <span class="font-weight-bold">SECCIÓN III</span>
              <p
                class="card-text text-truncate mb-0"
                style="font-size: 0.8rem;"
              >
                Rutas de Acceso
              </p>
            </div>
          </template>
          <SeccionIII
            v-if="isLoad"
            :id-atractivo="parseInt(idAtractivo)"
          />
        </b-tab>
        <!--/ cancellation and return -->

        <!-- my order -->
        <b-tab>
          <!-- title -->
          <template #title>
            <feather-icon
              icon="TrendingUpIcon"
              size="18"
              class="mr-1"
            />
            <div class="chat-info flex-grow-1">
              <span class="font-weight-bold">SECCIÓN IV</span>
              <p
                class="card-text text-truncate mb-0"
                style="font-size: 0.8rem;"
              >
                Tipo de Ingreso
              </p>
            </div>
          </template>
          <SeccionIV
            v-if="isLoad"
            :id-atractivo="parseInt(idAtractivo)"
          />
        </b-tab>
        <!--/ my order -->

        <!-- Product & Services -->
        <b-tab>
          <!-- title -->
          <template #title>
            <feather-icon
              icon="TagIcon"
              size="18"
              class="mr-1"
            />
            <div class="chat-info flex-grow-1">
              <span class="font-weight-bold">SECCIÓN V</span>
              <p
                class="card-text text-truncate mb-0"
                style="font-size: 0.8rem;"
              >
                Epoca Propicia de Visita
              </p>
            </div>
          </template>
          <SeccionV
            v-if="isLoad"
            :id-atractivo="parseInt(idAtractivo)"
          />
        </b-tab>
        <!-- Product & Services -->

        <b-tab>
          <!-- title -->
          <template #title>
            <feather-icon
              icon="LogOutIcon"
              size="18"
              class="mr-1"
            />
            <div class="chat-info flex-grow-1">
              <span class="font-weight-bold">SECCIÓN VI</span>
              <p
                class="card-text text-truncate mb-0"
                style="font-size: 0.8rem;"
              >
                Infraestructura
              </p>
            </div>
          </template>
          <seccion-v-i
            v-if="isLoad"
            :id-atractivo="parseInt(idAtractivo)"
          />
        </b-tab>

        <b-tab>
          <!-- title -->
          <template #title>
            <feather-icon
              icon="AwardIcon"
              size="18"
              class="mr-1"
            />
            <div class="chat-info flex-grow-1">
              <span class="font-weight-bold">SECCIÓN VII</span>
              <p
                class="card-text text-truncate mb-0"
                style="font-size: 0.8rem;"
              >
                Actividades Desarrolladas
              </p>
            </div>
          </template>
          <seccion-v-i-i
            v-if="isLoad"
            :id-atractivo="parseInt(idAtractivo)"
          />
        </b-tab>

        <b-tab>
          <!-- title -->
          <template #title>
            <feather-icon
              icon="ThumbsDownIcon"
              size="18"
              class="mr-1"
            />
            <div class="chat-info flex-grow-1">
              <span class="font-weight-bold">SECCIÓN VIII</span>
              <p
                class="card-text text-truncate mb-0"
                style="font-size: 0.8rem;"
              >
                Servicios Actuales
              </p>
            </div>
          </template>
          <seccion-v-i-i-i
            v-if="isLoad"
            :id-atractivo="parseInt(idAtractivo)"
          />
        </b-tab>

        <b-tab>
          <!-- title -->
          <template #title>
            <feather-icon
              icon="ColumnsIcon"
              size="18"
              class="mr-1"
            />
            <div class="chat-info flex-grow-1">
              <span class="font-weight-bold">SECCIÓN IX</span>
              <p
                class="card-text text-truncate mb-0"
                style="font-size: 0.8rem;"
              >
                Publicación
              </p>
            </div>
          </template>
          <seccion-i-x
            v-if="isLoad"
            :id-atractivo="parseInt(idAtractivo)"
            :data-edit="dataEdit"
            @refetch-data="getAtractivo"
          />
        </b-tab>
      </b-tabs>
    </section>
  </div>
</template>

<script>
import store from '@/store'
import { BTabs, BTab } from 'bootstrap-vue'
import { showToast, showError } from '@/helpers'
import SeccionI from './SeccionI.vue'
import SeccionII from './SeccionII.vue'
import SeccionIII from './SeccionIII.vue'
import SeccionIV from './SeccionIV.vue'
import SeccionV from './SeccionV.vue'
import SeccionVI from './SeccionVI.vue'
import SeccionVII from './SeccionVII.vue'
import SeccionVIII from './SeccionVIII.vue'
import SeccionIX from './SeccionIX.vue'

export default {
  components: {
    BTabs,
    BTab,
    SeccionI,
    SeccionII,
    SeccionIII,
    SeccionIV,
    SeccionV,
    SeccionVI,
    SeccionVII,
    SeccionVIII,
    SeccionIX,
  },
  data() {
    return {
      faqData: {},
      dataEdit: {},
      isLoad: false,
      idAtractivo: -1,
    }
  },
  created() {
    this.idAtractivo = this.$route.params.id
  },
  mounted() {
    this.getDatos()
  },
  methods: {
    async getAtractivo() {
      await store
        .dispatch('plataforma/ATRACTIVO_FIND_BY_ID', {
          id: this.idAtractivo,
        })
        .then(response => {
          if (response.data) {
            this.dataEdit = response.data
          }
        })
        .catch(error => {
          this.$bvToast.toast(error, {
            title: 'Ha ocurrido un error',
            variant: 'danger',
            solid: true,
          })
        })
    },
    async getDatos() {
      this.dataEdit = {}
      await this.getAtractivo()
      this.isLoad = true
    },
    showToast,
    showError,
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-atractivo-detalle.scss';
</style>
