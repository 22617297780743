<template>
  <b-card id="seccion-content">
    <b-card-header class="header-df">
      <b-col
        cols="12"
        md="6"
        class="pl-0"
      >
        <b-media vertical-align="center">
          <template #aside>
            <feather-icon
              size="36"
              icon="InfoIcon"
            />
          </template>
          <span class="font-weight-bold d-block text-nowrap">
            Actividades Desarrolladas
          </span>
          <small class="text-muted">SECCIÓN VII</small>
        </b-media>
      </b-col>
      <div class="float-right">
        <b-button-group size="sm">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="secondary"
            :to="{ name: 'rt-plataforma-info-atractivo' }"
          >
            <feather-icon
              class="mr-50"
              icon="ChevronLeftIcon"
            />
            <span class="align-middle">Atrás</span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            @click="saveFormSeven"
          >
            <feather-icon
              class="mr-50"
              icon="SaveIcon"
            />
            <span class="align-middle">Guardar</span>
          </b-button>
        </b-button-group>
      </div>
    </b-card-header>
    <b-card-body>
      <b-form class="pb-2 pl-0 pr-0 pt-1">
        <b-row
          v-if="isReady"
          class="justify-content-center"
        >
          <b-col
            cols="12"
            md="4"
          >
            <h6 class="text-dark mb-1">
              <i
                :class="
                  actividadesRecurso.find(item => item.idClasificacion === '21')
                    .iconoClasificacion
                "
              />
              <span class="align-middle ml-25">
                {{
                  actividadesRecurso.find(item => item.idClasificacion === '21')
                    .nombreClasificacion
                }}
              </span>
            </h6>
            <b-form-group>
              <b-form-checkbox
                v-for="acceso in actividadesRecurso.filter(
                  item => item.idClasificacion === '21',
                )"
                :key="`check-acceso-${acceso.idClasificacionAtractivo}`"
                v-model="acceso.activoClasificacionAtractivo"
                class="mb-1 custom-control-primary"
              >
                {{ acceso.descripcionClasificacionAtractivo }}
              </b-form-checkbox>
            </b-form-group>

            <h6 class="text-dark mb-1">
              <i
                :class="
                  actividadesRecurso.find(item => item.idClasificacion === '22')
                    .iconoClasificacion
                "
              />
              <span class="align-middle ml-25">
                {{
                  actividadesRecurso.find(item => item.idClasificacion === '22')
                    .nombreClasificacion
                }}
              </span>
            </h6>
            <b-form-group>
              <b-form-checkbox
                v-for="acceso in actividadesRecurso.filter(
                  item => item.idClasificacion === '22',
                )"
                :key="`check-acceso-${acceso.idClasificacionAtractivo}`"
                v-model="acceso.activoClasificacionAtractivo"
                class="mb-1 custom-control-primary"
              >
                {{ acceso.descripcionClasificacionAtractivo }}
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <h6 class="text-dark mb-1">
              <i
                :class="
                  actividadesRecurso.find(item => item.idClasificacion === '23')
                    .iconoClasificacion
                "
              />
              <span class="align-middle ml-25">
                {{
                  actividadesRecurso.find(item => item.idClasificacion === '23')
                    .nombreClasificacion
                }}
              </span>
            </h6>
            <b-form-group>
              <b-form-checkbox
                v-for="acceso in actividadesRecurso.filter(
                  item => item.idClasificacion === '23',
                )"
                :key="`check-acceso-${acceso.idClasificacionAtractivo}`"
                v-model="acceso.activoClasificacionAtractivo"
                class="mb-1 custom-control-primary"
              >
                {{ acceso.descripcionClasificacionAtractivo }}
              </b-form-checkbox>
            </b-form-group>

            <h6 class="text-dark mb-1">
              <i
                :class="
                  actividadesRecurso.find(item => item.idClasificacion === '24')
                    .iconoClasificacion
                "
              />
              <span class="align-middle ml-25">
                {{
                  actividadesRecurso.find(item => item.idClasificacion === '24')
                    .nombreClasificacion
                }}
              </span>
            </h6>
            <b-form-group>
              <b-form-checkbox
                v-for="acceso in actividadesRecurso.filter(
                  item => item.idClasificacion === '24',
                )"
                :key="`check-acceso-${acceso.idClasificacionAtractivo}`"
                v-model="acceso.activoClasificacionAtractivo"
                class="mb-1 custom-control-primary"
              >
                {{ acceso.descripcionClasificacionAtractivo }}
              </b-form-checkbox>
            </b-form-group>
            <h6 class="text-dark mb-1">
              <i
                :class="
                  actividadesRecurso.find(item => item.idClasificacion === '25')
                    .iconoClasificacion
                "
              />
              <span class="align-middle ml-25">
                {{
                  actividadesRecurso.find(item => item.idClasificacion === '25')
                    .nombreClasificacion
                }}
              </span>
            </h6>
            <b-form-group>
              <b-form-checkbox
                v-for="acceso in actividadesRecurso.filter(
                  item => item.idClasificacion === '25',
                )"
                :key="`check-acceso-${acceso.idClasificacionAtractivo}`"
                v-model="acceso.activoClasificacionAtractivo"
                class="mb-1 custom-control-primary"
              >
                {{ acceso.descripcionClasificacionAtractivo }}
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <h6 class="text-dark mb-1">
              <i
                :class="
                  actividadesRecurso.find(item => item.idClasificacion === '26')
                    .iconoClasificacion
                "
              />
              <span class="align-middle ml-25">
                {{
                  actividadesRecurso.find(item => item.idClasificacion === '26')
                    .nombreClasificacion
                }}
              </span>
            </h6>
            <b-form-group>
              <b-form-checkbox
                v-for="acceso in actividadesRecurso.filter(
                  item => item.idClasificacion === '26',
                )"
                :key="`check-acceso-${acceso.idClasificacionAtractivo}`"
                v-model="acceso.activoClasificacionAtractivo"
                class="mb-1 custom-control-primary"
              >
                {{ acceso.descripcionClasificacionAtractivo }}
              </b-form-checkbox>
            </b-form-group>

            <h6 class="text-dark mb-1">
              <i
                :class="
                  actividadesRecurso.find(item => item.idClasificacion === '27')
                    .iconoClasificacion
                "
              />
              <span class="align-middle ml-25">
                {{
                  actividadesRecurso.find(item => item.idClasificacion === '27')
                    .nombreClasificacion
                }}
              </span>
            </h6>
            <b-form-group>
              <b-form-checkbox
                v-for="acceso in actividadesRecurso.filter(
                  item => item.idClasificacion === '27',
                )"
                :key="`check-acceso-${acceso.idClasificacionAtractivo}`"
                v-model="acceso.activoClasificacionAtractivo"
                class="mb-1 custom-control-primary"
              >
                {{ acceso.descripcionClasificacionAtractivo }}
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-card-body>
  </b-card>
</template>

<script>
import store from '@/store'
import {
  BButton,
  BCol,
  BForm,
  BCard,
  BCardBody,
  BCardHeader,
  BMedia,
  BButtonGroup,
  BRow,
  BFormGroup,
  BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import { useNotify } from '@/helpers/toast'
/* import { quillEditor } from 'vue-quill-editor'
import vSelect from 'vue-select' */

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BCol,
    BForm,
    BCard,
    BCardBody,
    BCardHeader,
    BMedia,
    BButtonGroup,
    BRow,
    BFormCheckbox,
    BFormGroup,
  },
  props: {
    idAtractivo: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {}
  },
  computed: {
    isOtroBasico() {
      return this.selectedCheckBoxesBasica.includes(6)
    },
    isOtroTuristico() {
      return this.selectedCheckBoxesTuristica.includes(10)
    },
  },
  setup(props, context) {
    const { notify } = useNotify(context)
    const isBusy = ref(false)
    const isReady = ref(false)
    const loadingHead = ref(true)
    const loadingChecks = ref(true)
    const actividadesRecurso = ref()
    /* SEGMENTOS_RECURSOS 'INTERES_RECURSOS' */
    const getActividades = async () => {
      isBusy.value = true
      isReady.value = false
      await store
        .dispatch('plataforma/ATRACTIVO_GET_ACTIVIDADES', {
          idAtractivo: props.idAtractivo,
        })
        .then(response => {
          if (response) {
            actividadesRecurso.value = response
            isBusy.value = false
            isReady.value = true
          }
        })
        .catch(error => {
          let errorMessage = 'Intentelo en otro momento.'
          if (error.response) {
            errorMessage = error.response.data.message
          }
          notify('Ha ocurrido un error', errorMessage, 'danger')
        })
      isBusy.value = false
      isReady.value = true
    }

    const saveFormSeven = async () => {
      isBusy.value = true
      const formDataSeven = ref({})
      formDataSeven.value.idAtractivo = props.idAtractivo
      formDataSeven.value.seccion = 'actividad_recurso'
      formDataSeven.value.idsClasificacion = actividadesRecurso.value
        .filter(item => item.activoClasificacionAtractivo)
        .map(obj => obj.idClasificacionAtractivo)
        .join(',')

      await store
        .dispatch('plataforma/ATRACTIVO_CHECK_DETALLES', formDataSeven.value)
        .then(async response => {
          notify('Operación Exitosa', response.message, 'success')
        })
        .catch(error => {
          let errorMessage = 'Intentelo en otro momento.'
          if (error.response) {
            errorMessage = error.response.data.message
          }
          notify('Ha ocurrido un error', errorMessage, 'danger')
        })
      isBusy.value = false
    }
    const loadData = async () => {
      await getActividades()
    }
    loadData()
    return {
      loadingHead,
      loadingChecks,
      isBusy,
      isReady,
      actividadesRecurso,
      saveFormSeven,
      getActividades,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.header-df {
  background-color: rgba(34, 41, 47, 0.03) !important;
  border-bottom: 1px solid rgba(34, 41, 47, 0.125) !important;
}

.nav-tabs {
  background: #f6f6f6 !important;
}

.actions-bar {
  background: #f6f6f6 !important;
  justify-content: end;
}

.form-group {
  margin-bottom: 0.5rem !important;
}

#seccion-content.card > .card-body {
  padding-top: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

#seccion-content {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
