<template>
  <b-card id="seccion-content">
    <b-card-header class="header-df">
      <b-col
        cols="12"
        md="6"
        class="pl-0"
      >
        <b-media vertical-align="center">
          <template #aside>
            <feather-icon
              size="36"
              icon="InfoIcon"
            />
          </template>
          <span class="font-weight-bold d-block text-nowrap">
            Epoca Propicia de Visita
          </span>
          <small class="text-muted">SECCIÓN V</small>
        </b-media>
      </b-col>
      <div class="float-right">
        <b-button-group size="sm">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="secondary"
            :to="{ name: 'rt-plataforma-info-atractivo' }"
          >
            <feather-icon
              class="mr-50"
              icon="ChevronLeftIcon"
            />
            <span class="align-middle">Atrás</span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            @click="saveFormFive"
          >
            <feather-icon
              class="mr-50"
              icon="SaveIcon"
            />
            <span class="align-middle">Guardar</span>
          </b-button>
        </b-button-group>
      </div>
    </b-card-header>
    <b-card-body>
      <b-form class="pb-2 pl-0 pr-0 pt-1">
        <b-row class="justify-content-center">
          <b-col
            cols="12"
            md="12"
          >
            <BTable
              responsive
              :items="items"
              :fields="fields"
              class="mb-0"
            >
              <template #head(acciones)="">
                <b-button
                  v-if="items.length === 0"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-modal.modal-center
                  size="sm"
                  variant="primary"
                  @click="isEdit = false"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">Epoca</span>
                </b-button>
              </template>
              <template #cell(acciones)="data">
                <span class="text-nowrap">
                  <span>
                    <div class="d-inline wl100">
                      <feather-icon
                        :id="`btnEditTipoIngreso${data.index}`"
                        icon="EditIcon"
                        class="cursor-pointer mr-50"
                        @click="editFromTableV(data.item)"
                      />
                      <feather-icon
                        :id="`btnDeleteTipoIngreso${data.index}`"
                        icon="Trash2Icon"
                        class="cursor-pointer"
                        @click="deleteFromTableV(data.item)"
                      />
                    </div> </span></span>
              </template>

              <!-- Optional default data cell scoped slot -->
              <template #cell()="data">
                {{ data.value }}
              </template>
            </BTable>
          </b-col>
        </b-row>
      </b-form>
    </b-card-body>
    <b-modal
      id="modal-center"
      ref="insertEpoca"
      centered
      title="Epoca propicia de visita al Atractivo"
      no-close-on-backdrop
      size="lg"
    >
      <template #modal-footer>
        <div class="float-right">
          <b-button-group size="sm">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="secondary"
              @click="closeModal"
            >
              <feather-icon
                class="mr-50"
                icon="XIcon"
              />
              <span class="align-middle">Cancelar</span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="primary"
              @click="addTable"
            >
              <feather-icon
                class="mr-50"
                icon="PlusIcon"
              />
              <span class="align-middle">Agregar</span>
            </b-button>
          </b-button-group>
        </div>
      </template>
      <b-form class="pb-2 pl-0 pr-0 pt-1">
        <b-row>
          <b-col
            cols="12"
            md="12"
          >
            <BFormGroup
              label="Temporada"
              label-for="destino"
            >
              <v-select
                id="destino"
                v-model="selectClima"
                label="text"
                :reduce="m => m.text"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="optionsClima"
                @input="onChangeClima"
              />
            </BFormGroup>
          </b-col>
          <b-col
            v-if="isMeses"
            cols="12"
            md="12"
          >
            <BFormGroup
              label="Seleccione los meses"
              label-for="meses"
            >
              <v-select
                id="meses"
                v-model="selectMonths"
                label="text"
                :reduce="m => m.text"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="months"
                multiple
              />
            </BFormGroup>
          </b-col>
          <b-col
            cols="12"
            md="12"
          >
            <BFormGroup
              label="Especificaciones"
              label-for="especiF"
            >
              <BFormTextarea
                id="especiF"
                v-model="formV.especificaciones"
                :options="editorOption"
                placeholder="Ingrese una descripción"
                rows="2"
                max-rows="20"
                class="border-bottom-0"
              />
            </BFormGroup>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="12"
          >
            <BFormGroup
              label="Horario de Visita"
              label-for="horario"
            >
              <BFormTextarea
                id="horario"
                v-model="formV.horariosVisita"
                :options="editorOption"
                placeholder="Ingrese una descripción"
                rows="2"
                max-rows="20"
                class="border-bottom-0"
              />
            </BFormGroup>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="12"
          >
            <BFormGroup
              label="Tiempo recomendado de Visita"
              label-for="tiempo"
            >
              <BFormTextarea
                id="tiempo"
                v-model="formV.tiempoVisita"
                placeholder="Ingrese una descripción"
                rows="2"
                max-rows="20"
                class="border-bottom-0"
              />
            </BFormGroup>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            cols="12"
            md="12"
          >
            <BFormGroup
              label="Recomendaciones"
              label-for="recomendaciones"
            >
              <BFormTextarea
                id="recomendaciones"
                v-model="formV.recomendaciones"
                placeholder="Ingrese una descripción"
                rows="2"
                max-rows="20"
                class="border-bottom-0"
              />
            </BFormGroup>
          </b-col>
        </b-row>
      </b-form>

      <!--   <b-card-text> qwe </b-card-text> -->
    </b-modal>
  </b-card>
</template>

<script>
import store from '@/store'
import {
  BTable,
  BButton,
  BCol,
  BForm,
  BCard,
  BCardBody,
  BCardHeader,
  BMedia,
  BButtonGroup,
  BRow,
  BFormGroup,
  BFormTextarea,
  /* BFormInput,

  BFormCheckboxGroup,
  BFormCheckbox, */
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import vSelect from 'vue-select'
/* import { quillEditor } from 'vue-quill-editor'
 */
import { useNotify } from '@/helpers/toast'

export default {
  directives: {
    Ripple,
  },
  components: {
    BFormTextarea,
    BButton,
    BCol,
    BForm,
    BCard,
    BCardBody,
    BCardHeader,
    BMedia,
    BButtonGroup,
    BRow,
    BFormGroup,
    vSelect,
    BTable,
  },
  props: {
    idAtractivo: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'idEpocaVisita',
          label: 'id',
          thClass: 'd-none',
          tdClass: 'd-none',
        },
        {
          key: 'temporada',
          label: 'Temporada',
        },
        {
          key: 'especificaciones',
          label: 'especiF',
        },
        {
          key: 'horariosVisita',
          label: 'Horario',
        },
        {
          key: 'tiempoVisita',
          label: 'Tiempo Reco.',
        },
        {
          key: 'recomendaciones',
          label: 'Recomendaciones',
        },
        {
          key: 'Acciones',
          label: 'Acciones',
          tdClass: ['text-center', 'bg-white'],
          thClass: 'text-center',
        },
      ],
      optionsClima: [
        { text: 'TODO EL AÑO' },
        { text: 'ALGUNOS MESES' },
        { text: 'FINES DE SEMANA' },
        { text: 'FERIADOS' },
      ],
      months: [
        { text: 'Enero', value: 1 },
        { text: 'Febrero', value: 2 },
        { text: 'Marzo', value: 3 },
        { text: 'Abril', value: 4 },
        { text: 'Mayo', value: 5 },
        { text: 'Junio', value: 6 },
        { text: 'Julio', value: 7 },
        { text: 'Agosto', value: 8 },
        { text: 'Septiembre', value: 9 },
        { text: 'Octubre', value: 10 },
        { text: 'Noviembre', value: 11 },
        { text: 'Diciembre', value: 12 },
      ],
      items: [],
    }
  },
  mounted() {
    this.getEpocaVisita()
  },
  methods: {
    closeModal() {
      this.clearFormV()
      this.$refs.insertEpoca.hide()
    },
    clean() {
      this.items = []
    },
    onChangeClima(event) {
      this.isMeses = false
      this.selectMonths = []
      if (event === 'ALGUNOS MESES') {
        this.isMeses = true
      }
    },
    addTable() {
      this.$refs.insertEpoca.hide()
      let tempor = `${this.selectClima}`
      try {
        if (this.selectMonths.length > 0) {
          const textSelect = this.selectMonths.join(',')
          tempor = `${this.selectClima} - ${textSelect}`
        }
      } catch (error) {
        console.log(error)
      }
      if (this.isEdit) {
        const index = this.items.findIndex(
          item => item.idEpocaVisita === this.idEpocaVisitaEdit,
        )
        this.items[index].temporada = tempor
        this.items[index].especificaciones = this.formV.especificaciones
        this.items[index].horariosVisita = this.formV.horariosVisita
        this.items[index].tiempoVisita = this.formV.tiempoVisita
        this.items[index].recomendaciones = this.formV.recomendaciones
        this.items[index].idAtractivo = this.$props.idAtractivo
      } else {
        this.items.push({
          idEpocaVisita: -1,
          temporada: tempor,
          especificaciones: this.formV.especificaciones,
          horariosVisita: this.formV.horariosVisita,
          tiempoVisita: this.formV.tiempoVisita,
          recomendaciones: this.formV.recomendaciones,
          idAtractivo: this.$props.idAtractivo,
        })
      }

      this.clearFormV()
    },
    editFromTableV(item) {
      this.$refs.insertEpoca.show()
      this.formV = {
        especificaciones: item.especificaciones,
        horariosVisita: item.horariosVisita,
        tiempoVisita: item.tiempoVisita,
        recomendaciones: item.recomendaciones,
      }
      this.idEpocaVisitaEdit = item.idEpocaVisita
      this.isEdit = true

      if (item.temporada.includes('-')) {
        const temp = item.temporada.split('-')
        this.selectClima = this.optionsClima.find(
          option => option.text === temp['0'].trim(),
        )
        this.selectMonths = temp[1].trim().split(',')
        this.isMeses = true
      } else {
        this.selectClima = this.optionsClima.find(
          option => option.text === item.temporada.trim(),
        )
      }
    },

    deleteFromTableV(event) {
      const index = this.items.findIndex(item => item === event)
      this.items.splice(index, 1)
    },
    clearFormV() {
      this.formV = {
        especificaciones: '',
        horariosVisita: '',
        tiempoVisita: '',
        recomendaciones: '',
      }
      this.selectMonths = []
      this.selectClima = null
    },
    async getEpocaVisita() {
      this.isBusy = true
      this.isReady = false
      await store
        .dispatch('plataforma/ATRACTIVO_GET_EPOCA_VISITA', {
          idAtractivo: this.$props.idAtractivo,
        })
        .then(response => {
          this.items = response
        })
        .catch(error => {
          let errorMessage = 'Intentelo en otro momento.'
          if (error.response) {
            errorMessage = error.response.data.message
          }
          this.notify('Ha ocurrido un error', errorMessage, 'danger')
        })
        .finally(() => {
          this.isBusy = false
          this.isReady = true
        })
    },
    async saveFormFive() {
      this.isBusy = true
      await store
        .dispatch('plataforma/ATRACTIVO_CREATE_EPOCA_VISITA', this.items[0])
        .then(async response => {
          this.notify('Operación Exitosa', response.message, 'success')
        })
        .catch(error => {
          let errorMessage = 'Intentelo en otro momento.'
          if (error.response) {
            errorMessage = error.response.data.message
          }
          this.notify('Ha ocurrido un error', errorMessage, 'danger')
        })
        .finally(() => {
          this.isBusy = false
        })
    },
  },
  setup(props, context) {
    const formV = ref({
      especificaciones: '',
      horariosVisita: '',
      tiempoVisita: '',
      recomendaciones: '',
    })
    const isBusy = ref(false)
    const selectClima = ref()
    const isMeses = ref(false)
    const selectMonths = ref([])
    const { notify } = useNotify(context)
    const isEdit = ref(false)
    const idEpocaVisitaEdit = ref(-1)
    const toolbarOptions = [
      ['bold', 'italic', 'underline', 'strike'],
      [{ align: [] }],
      ['blockquote'],
      [{ header: 1 }, { header: 2 }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'image'],
      // [{ script: 'sub' }, { script: 'super' }],
      [{ indent: '-1' }, { indent: '+1' }],
      [{ direction: 'rtl' }],
      // [{ size: ['small', false, 'large', 'huge'] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] }, { background: [] }],
      // [{ font: [] }],
      // ['clean'],
    ]
    /* array list of months */

    const editorOption = {
      modules: {
        toolbar: toolbarOptions,
      },
      placeholder: 'Ingrese descripción',
    }
    return {
      isBusy,
      editorOption,
      formV,
      selectClima,
      isMeses,
      selectMonths,
      notify,
      isEdit,
      idEpocaVisitaEdit,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.header-df {
  background-color: rgba(34, 41, 47, 0.03) !important;
  border-bottom: 1px solid rgba(34, 41, 47, 0.125) !important;
}

.nav-tabs {
  background: #f6f6f6 !important;
}

.actions-bar {
  background: #f6f6f6 !important;
  justify-content: end;
}

.form-group {
  margin-bottom: 0.5rem !important;
}

#seccion-content.card > .card-body {
  padding-top: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

#seccion-content {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
