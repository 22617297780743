<template>
  <b-card id="seccion-iii-content">
    <b-card-header class="header-df">
      <BCol
        cols="12"
        md="6"
        class="pl-0"
      >
        <b-media vertical-align="center">
          <template #aside>
            <feather-icon
              size="36"
              icon="InfoIcon"
            />
          </template>
          <span class="font-weight-bold d-block text-nowrap">
            Rutas de Acceso
          </span>
          <small class="text-muted">SECCIÓN III</small>
        </b-media>
      </BCol>
      <div class="float-right">
        <b-button-group size="sm">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="secondary"
            :to="{ name: 'rt-plataforma-info-atractivo' }"
          >
            <feather-icon
              class="mr-50"
              icon="ChevronLeftIcon"
            />
            <span class="align-middle">Atrás</span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            @click="saveFormFourth"
          >
            <feather-icon
              class="mr-50"
              icon="SaveIcon"
            />
            <span class="align-middle">Guardar</span>
          </b-button>
        </b-button-group>
      </div>
    </b-card-header>
    <b-card-body>
      <b-form class="pb-2 pl-0 pr-0 pt-1">
        <b-tabs v-model="rutaSel">
          <b-tab
            v-for="rutax in rutas"
            :key="'dyn-tab-' + rutax"
          >
            <template #title>
              <feather-icon icon="ChevronRightIcon" />
              <span>Ruta {{ rutax }}</span>
            </template>
            <b-table
              responsive="sm"
              :items="items.filter(el => el.nroruta == rutaSel + 1)"
              :fields="fields"
              empty-text="No matching records found"
            >
              <template #cell(tramoInicial)="data">
                <b-media vertical-align="center">
                  <template #aside>
                    <b-avatar
                      rounded
                      size="32"
                      variant="light-dark"
                    >
                      <font-awesome-icon
                        :icon="data.item.accesoAtractivo.clasificacion.icono"
                      />
                    </b-avatar>
                  </template>
                  <span class="font-weight-bold d-block text-nowrap">
                    {{ data.item.tramoInicial }}
                    <feather-icon
                      icon="ChevronsRightIcon"
                      size="12"
                    />
                    {{ data.item.tramoFinal }}
                  </span>
                  <b-badge
                    variant="light-info"
                    class="mr-50"
                  >
                    <feather-icon
                      icon="CheckCircleIcon"
                      size="12"
                    />
                    {{ data.item.accesoAtractivo.descripcion }}
                  </b-badge>
                  <b-badge
                    variant="light-danger"
                    class="mr-50"
                  >
                    <feather-icon
                      icon="AlertTriangleIcon"
                      size="12"
                    />
                    {{ data.item.viaAcceso }}
                  </b-badge>
                  <b-badge
                    variant="light-warning"
                    class="mr-50"
                  >
                    <feather-icon
                      icon="MaximizeIcon"
                      size="12"
                    />
                    Distancia {{ data.item.distanciaKms }}
                  </b-badge>
                  <b-badge variant="light-success">
                    <feather-icon
                      icon="ClockIcon"
                      size="12"
                    />
                    Tiempo {{ data.item.distanciaTiempo }}
                  </b-badge>
                </b-media>
              </template>
              <template #head(actions)="[]">
                <b-button
                  :id="`btnAddTramo${rutax}`"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  size="sm"
                  variant="primary"
                  @click="openModal"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">Tramo</span>
                </b-button>
              </template>
              <!-- A custom formatted column -->
              <template #cell(actions)="data">
                <div class="d-inline wl100">
                  <feather-icon
                    :id="`btnEditActividad${data.item.idItinerario}`"
                    v-b-tooltip.hover.top="'Editar Tramo'"
                    icon="EditIcon"
                    class="cursor-pointer mr-50"
                    @click="editFromTableThree(data.item)"
                  />
                  <feather-icon
                    :id="`btnDeleteActividad${data.item.idItinerario}`"
                    v-b-tooltip.hover.top="'Eliminar Tramo'"
                    icon="Trash2Icon"
                    class="cursor-pointer"
                    @click="deleteActividad(data.item)"
                  />
                </div>
              </template>
            </b-table>
          </b-tab>

          <!-- New Tab Button (Using tabs-end slot) -->
          <template #tabs-start>
            <b-nav-item
              role="presentation"
            >
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                size="sm"
                variant="primary"
                @click.prevent="newRuta"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-50"
                />
                <span class="align-middle">Ruta</span>
              </b-button>
            </b-nav-item>
          </template>

          <!-- Render this if no tabs -->
          <template #empty>
            <div class="text-center text-muted">
              Las rutas de acceso están vacías<br>
              Click en <b>+ Agregar Ruta</b> para empezar.
            </div>
          </template>
        </b-tabs>
      </b-form>
    </b-card-body>
    <b-modal
      v-model="modalshow"
      centered
      :title="titleModal"
      no-close-on-backdrop
      size="m"
    >
      <template #modal-footer>
        <div class="float-right">
          <b-button-group size="sm">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="secondary"
              @click="closeModal"
            >
              <feather-icon
                class="mr-50"
                icon="XIcon"
              />
              <span class="align-middle">Cancelar</span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="primary"
              @click="addEditTable"
            >
              <feather-icon
                class="mr-50"
                icon="CheckIcon"
              />
              <span class="align-middle">Aceptar</span>
            </b-button>
          </b-button-group>
        </div>
      </template>
      <b-form class="pb-2 pl-0 pr-0 pt-1">
        <BRow>
          <BCol
            cols="12"
            md="12"
          >
            <BFormGroup
              label="Tramo Inicial"
              label-for="semiRestringido"
            >
              <BFormInput
                id="tramoI"
                v-model="formThree.tramoInicial"
                placeholder=""
              />
            </BFormGroup>
          </BCol>
          <BCol
            cols="12"
            md="12"
          >
            <BFormGroup
              label="Tramo Final"
              label-for="semiRestringido"
            >
              <BFormInput
                id="tramoF"
                v-model="formThree.tramoFinal"
                placeholder=""
              />
            </BFormGroup>
          </BCol>
          <BCol
            cols="12"
            md="12"
          >
            <BFormGroup
              label="Acceso"
              label-for="acceso"
            >
              <b-form-select
                v-model="accesoSel"
                :options="optAccesos"
              />
            </BFormGroup>
          </BCol>
          <BCol
            cols="12"
            md="12"
          >
            <BFormGroup
              label="Via Acceso"
              label-for="via-acceso"
            >
              <BFormInput
                id="via-acceso"
                v-model="formThree.viaAcceso"
                placeholder=""
              />
            </BFormGroup>
          </BCol>
          <BCol
            cols="12"
            md="12"
          >
            <BFormGroup
              label="Distancia KM"
              label-for="distanciaKm"
            >
              <BFormInput
                id="tiempo"
                v-model="formThree.distanciaKms"
                placeholder=""
              />
            </BFormGroup>
          </BCol>
          <BCol
            cols="12"
            md="12"
          >
            <BFormGroup
              label="Distancia Tiempo"
              label-for="dtiempo"
            >
              <BFormInput
                id="dtiempo"
                v-model="formThree.distanciaTiempo"
                placeholder=""
              />
            </BFormGroup>
          </BCol>
        </BRow>
      </b-form>

      <!--   <b-card-text> qwe </b-card-text> -->
    </b-modal>
  </b-card>
</template>

<script>
import {
  BFormSelect,
  BBadge,
  BAvatar,
  BNavItem,
  VBTooltip,
  BTabs,
  BTab,
  BTable,
  BButton,
  BCol,
  BForm,
  BCard,
  BCardBody,
  BCardHeader,
  BMedia,
  BButtonGroup,
  BRow,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import store from '@/store'
import { useNotify } from '@/helpers/toast'

export default {
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    BFormSelect,
    BBadge,
    BAvatar,
    BNavItem,
    BTabs,
    BTab,
    BButton,
    BCol,
    BForm,
    BCard,
    BCardBody,
    BCardHeader,
    BMedia,
    BButtonGroup,
    BRow,
    BFormGroup,
    BTable,
    BFormInput,
  },
  props: {
    idAtractivo: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'tramoInicial',
          label: 'Tramo',
          thClass: 'align-middle',
          tdClass: 'bg-white',
        },
        {
          key: 'actions',
          label: 'Acciones',
          tdClass: ['text-center', 'bg-white', 'w120'],
          thClass: ['text-center', 'w120'],
        },
      ],
      fieldsTarifa: [
        { key: 'segmento', label: 'Segmento' },
        { key: 'tarifa', label: 'Tarifa S/. ' },
        'Acciones',
      ],
      titleModal: 'Nuevo Tramo',
      ruta: [],
      rutas: [],
      rutaSel: 0,
      formTramo: {},
      optAccesos: [],
      modalshow: false,
      accesoSel: {},
      items: [
        {
          nroruta: 1,
          tramoInicial: '',
          tramoFinal: '',
          viaAcceso: '',
          distanciaKms: 0,
          distanciaTiempo: 0,
          idAtractivo: this.$props.idAtractivo,
          ruta: [],
        },
      ],
      formThree: {},
      optionsTipo: [
        { text: 'LIBRE', value: 1 },
        { text: 'BOLETO O TICKET', value: 2 },
        { text: 'SEMI RESTRINGIDO', value: 3 },
        { text: 'OTRO', value: 4 },
      ],
    }
  },
  mounted() {
    this.getRutaAccesos()
  },
  methods: {
    async newRuta() {
      await this.rutas.push(this.rutas.length + 1)
      this.rutaSel = 0
    },
    closeModal() {
      this.clearFormIII()
      this.$refs.insertRuta.hide()
    },
    clean() {
      this.items = []
    },
    async addEditTable() {
      this.isBusy = true
      this.formThree.accesoAtractivo = {
        idAccesoRecurso: Number(this.accesoSel),
      }
      this.formThree.idAtractivo = this.$props.idAtractivo
      this.formThree.nroruta = this.rutaSel + 1

      let service = 'RUTA_ACCESO_CREATE'
      if (this.isEdit) {
        service = 'RUTA_ACCESO_UPDATE'
      }

      await store.dispatch(`plataforma/${service}`, this.formThree)
        .then(async response => {
          await this.getRutaAccesos()
          this.notify('Operación Exitosa', response.message, 'success')
          this.modalshow = false
        })
        .catch(error => {
          let errorMessage = 'Intentelo en otro momento.'
          if (error.response) {
            errorMessage = error.response.data.message
          }
          this.notify('Ha ocurrido un error', errorMessage, 'danger')
        })
      this.isBusy = false
    },
    async editFromTableThree(item) {
      this.titleModal = 'Editar Tramo'
      this.isEdit = true
      await this.getAccesos()
      this.formThree = item
      this.modalshow = true
      // this.$refs.insertRuta.show()
    },

    deleteFromTableThree(event) {
      const index = this.items.findIndex(item => item.idTipoIngreso === event)
      this.items.splice(index, 1)
    },
    clearFormIII() {
      this.formThree = {}
    },
    onChangeTipo() {
      this.formIV.textarea = ''
      this.tarifaList = []
    },
    async getRutaAccesos() {
      this.isBusy = true
      this.isReady = false
      this.items = []
      this.rutas = []
      await store
        .dispatch('plataforma/ATRACTIVO_GET_RUTA_ACCESOS', {
          idAtractivo: this.$props.idAtractivo,
        })
        .then(response => {
          if (response) {
            this.items = response
            this.rutas = [...new Set(this.items.map(item => item.nroruta))]
            this.rutaSel = 0
          }
        })
        .catch(error => {
          let errorMessage = 'Intentelo en otro momento.'
          if (error.response) {
            errorMessage = error.response.data.message
          }
          this.notify('Ha ocurrido un error', errorMessage, 'danger')
        })
        .finally(() => {
          this.isBusy = false
          this.isReady = true
        })
    },
    async getAccesos() {
      this.isBusy = true
      this.isReady = false
      this.optAccesos = []
      await store
        .dispatch('plataforma/ATRACTIVO_GET_ACCESOS', {
          idAtractivo: this.$props.idAtractivo,
        })
        .then(response => {
          if (response) {
            let objAcc = {}
            let lastClas = -1
            const activos = response.filter(ele => ele.activoClasificacionAtractivo)
            activos.forEach((ele, index) => {
              if (ele.idClasificacion !== lastClas) {
                if (lastClas !== -1) {
                  this.optAccesos.push(objAcc)
                }
                objAcc = {
                  label: ele.nombreClasificacion,
                  icon: ele.iconoClasificacion,
                  options: [],
                }
                lastClas = ele.idClasificacion
              }
              objAcc.options.push({
                value: ele.idClasificacionAtractivo,
                text: ele.descripcionClasificacionAtractivo,
              })
              if (index === activos.length - 1) {
                this.optAccesos.push(objAcc)
              }
            })
          }
        })
        .catch(error => {
          let errorMessage = 'Intentelo en otro momento.'
          if (error.response) {
            errorMessage = error.response.data.message
          }
          this.notify('Ha ocurrido un error', errorMessage, 'danger')
        })
      this.isBusy = false
      this.isReady = true
    },
    async openModal() {
      this.titleModal = 'Nuevo Tramo'
      this.isEdit = false
      await this.getAccesos()
      this.modalshow = true
    },
    async saveFormFourth() {
      this.isBusy = true
      const formData = ref({})
      formData.value.idAtractivo = this.$props.idAtractivo
      formData.value.datos = this.items[0].datos
      formData.value.idTipoIngreso = this.items[0].idTipoIngreso
      formData.value.tipo = this.items[0].tipo.trim()
      if (this.items[0].tipo.includes('BOLETO')) {
        formData.value.datos = this.items[0].datos.join(',')
      }
      await store
        .dispatch('plataforma/ATRACTIVO_CREATE_TIPO_INGRESO', formData.value)
        .then(async response => {
          this.notify('Operación Exitosa', response.message, 'success')
        })
        .catch(error => {
          let errorMessage = 'Intentelo en otro momento.'
          if (error.response) {
            errorMessage = error.response.data.message
          }
          this.notify('Ha ocurrido un error', errorMessage, 'danger')
        })
      this.isBusy = false
    },
  },
  setup(props, context) {
    const { notify } = useNotify(context)
    const formIV = ref({})
    const isBusy = ref(false)
    const isReady = ref(false)
    const selectTipo = ref(0)
    const isEdit = ref(true)

    return {
      isEdit,
      isBusy,
      formIV,
      selectTipo,
      notify,
      isReady,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.header-df {
  background-color: rgba(34, 41, 47, 0.03) !important;
  border-bottom: 1px solid rgba(34, 41, 47, 0.125) !important;
}

.nav-tabs {
  background: #f6f6f6 !important;
}

.actions-bar {
  background: #f6f6f6 !important;
  justify-content: end;
}

.form-group {
  margin-bottom: 0.5rem !important;
}

#seccion-content.card > .card-body {
  padding-top: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.w120{
  width: 120px !important;
}

.b-table th, .b-table td{
  padding: 0.72rem !important;
}

#seccion-content {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
#seccion-iii-content .card-body {
  background: #f6f6f6!important;
}
</style>
