<template>
  <b-card id="seccion-content">
    <b-card-header class="header-df">
      <BCol
        cols="12"
        md="6"
        class="pl-0"
      >
        <b-media vertical-align="center">
          <template #aside>
            <feather-icon
              size="36"
              icon="InfoIcon"
            />
          </template>
          <span class="font-weight-bold d-block text-nowrap">
            Tipo de Ingreso
          </span>
          <small class="text-muted">SECCIÓN IV</small>
        </b-media>
      </BCol>
      <div class="float-right">
        <b-button-group size="sm">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="secondary"
            :to="{ name: 'rt-plataforma-info-atractivo' }"
          >
            <feather-icon
              class="mr-50"
              icon="ChevronLeftIcon"
            />
            <span class="align-middle">Atrás</span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            @click="saveFormFourth"
          >
            <feather-icon
              class="mr-50"
              icon="SaveIcon"
            />
            <span class="align-middle">Guardar</span>
          </b-button>
        </b-button-group>
      </div>
    </b-card-header>
    <b-card-body>
      <b-form class="pb-2 pl-0 pr-0 pt-1">
        <BRow class="justify-content-center">
          <BCol
            cols="12"
            md="12"
          >
            <!-- <div
              v-if="items.length == 0"
              class="demo-spacing-0"
            >
              <BAlert
                variant="primary"
                show
              >
                <div class="alert-body">
                  <span><strong>SIN REGISTROS</strong> Haga click en 'Añadir Tio de
                    Ingreso' para insertar un tipo de ingreso</span>
                </div>
              </BAlert>
            </div> -->
            <BTable
              responsive
              :items="items"
              :fields="fields"
              class="mb-0"
            >
              <template
                v-if="items.length == 0"
                #head(acciones)="[a]"
              >
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-modal.modal-ingreso
                  size="sm"
                  variant="primary"
                  @click="isEdit = false"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-50 "
                    :class="a"
                  />
                  <span class="align-middle">Tipo Ingreso</span>
                </b-button>
              </template>
              <template #cell(tipo)="data">
                <span class="font-weight-bold d-block text-nowrap">
                  {{ data.item.tipo }}
                </span>
                <small class="text-muted d-block ">PREDEFINIDO</small>
              </template>
              <template #cell(datos)="data">
                <span v-if="!isBoleto(data.item.tipo)">{{ data.item.datos }}</span>
                <span v-else>
                  <div>
                    <BBadge
                      v-for="tip in data.item.datos"
                      :key="tip.id"
                      variant="success"
                      class="ml-1"
                    >
                      {{ tip.segmento }} S/. {{ tip.tarifa }}
                      <!--  {{ tip.replace('-', ' S/. ') }} -->
                    </BBadge>
                  </div>
                </span>
              </template>
              <template #cell(acciones)="data">
                <div class="d-inline wl100">
                  <feather-icon
                    icon="EditIcon"
                    class="cursor-pointer mr-50"
                    @click="editFromTable(data.item)"
                  />
                  <feather-icon

                    icon="Trash2Icon"
                    class="cursor-pointer"
                    @click="deleteFromTable(data.item)"
                  />
                </div>
              </template>
            </BTable>
          </BCol>
        </BRow>
      </b-form>
    </b-card-body>
    <b-modal
      id="modal-ingreso"
      ref="insertAsociacion"
      centered
      title="Tipo de Ingreso"
      no-close-on-backdrop
      size="m"
    >
      <template #modal-footer>
        <div class="float-right">
          <b-button-group size="sm">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="secondary"
              @click="closeModal"
            >
              <feather-icon
                class="mr-50"
                icon="XIcon"
              />
              <span class="align-middle">Cancelar</span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="primary"
              @click="addTable"
            >
              <feather-icon
                class="mr-50"
                icon="CheckIcon"
              />
              <span class="align-middle">Aceptar</span>
            </b-button>
          </b-button-group>
        </div>
      </template>
      <b-form class="pb-2 pl-0 pr-0 pt-1">
        <BRow>
          <BCol
            cols="12"
            md="12"
          >
            <BFormGroup
              label="Tipo"
              label-for="tipo"
            >
              <v-select
                id="tipo"
                v-model="selectTipo"
                label="text"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="optionsTipo"
                @input="onChangeTipo"
              /></BFormGroup>
          </BCol>
        </BRow>
        <BRow v-if="selectTipo.value == 3">
          <BCol
            cols="12"
            md="12"
          >
            <BFormGroup
              label="Previo Permiso a"
              label-for="semiRestringido"
            >
              <BFormTextarea
                id="semiRestringido"
                v-model="formIV.textarea"
                placeholder="Detallar datos de contacto de la persona e institución entre quien se debe tramitar el permiso"
                rows="2"
                max-rows="20"
                class="border-bottom-0"
              />
            </BFormGroup>
          </BCol>
        </BRow>
        <BRow v-if="selectTipo.value == 4">
          <BCol
            cols="12"
            md="12"
          >
            <BFormGroup
              label="Especificar"
              label-for="semiRestringido"
            >
              <BFormTextarea
                id="semiRestringido"
                v-model="formIV.textarea"
                placeholder=""
                rows="2"
                max-rows="20"
                class="border-bottom-0"
              />
            </BFormGroup>
          </BCol>
        </BRow>
        <BRow v-if="selectTipo.value == 2">
          <BCol
            cols="12"
            md="12"
          >
            <div>
              <BRow>
                <BCol
                  cols="12"
                  md="12"
                >
                  <div class="d-flex m-0 p-0 justify-content-end">
                    <BButton
                      variant="primary"
                      size="sm"
                      @click="addTableTarifaList"
                    >
                      <span class="text-nowrap">Nueva tarifa</span>
                    </BButton>
                  </div>
                </BCol>
              </BRow>
            </div>
            <div
              v-if="tarifaList.length == 0"
              class="demo-spacing-0 pt-1"
            >
              <BAlert
                variant="primary"
                show
              >
                <div class="alert-body">
                  <span><strong>SIN REGISTROS</strong> Haga click en 'Nueva tarifa'
                    para insertar una tarifa</span>
                </div>
              </BAlert>
            </div>
            <BTable
              v-else
              responsive
              :items="tarifaList"
              :fields="fieldsTarifa"
              class="mb-0"
            >
              <template #cell(segmento)="data">
                <span v-if="!data.item.editable">
                  {{ data.item.segmento }}
                </span>
                <BRow v-else>
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <BFormInput
                      id="tiempo"
                      v-model="data.item.segmento"
                      placeholder=""
                    />
                  </b-col>
                </BRow>
              </template>
              <template #cell(tarifa)="data">
                <span v-if="!data.item.editable">
                  {{ data.item.tarifa }}
                </span>
                <BRow v-else>
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <BFormInput
                      id="tiempo"
                      v-model="data.item.tarifa"
                      placeholder="S/. 0.00"
                    />
                  </b-col>
                </BRow>
              </template>
              <template #cell(acciones)="data">
                <span class="text-nowrap">
                  <span v-if="!data.item.editable">
                    <b-dropdown
                      variant="link"
                      toggle-class="text-decoration-none"
                      no-caret
                    >
                      <template v-slot:button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="text-body align-middle mr-25"
                        />
                      </template>
                      <b-dropdown-item @click="editTarifaTable(data.item.id)">
                        <feather-icon
                          icon="Edit2Icon"
                          class="mr-50"
                        />
                        <span>Editar</span>
                      </b-dropdown-item>

                      <b-dropdown-item
                        @click="deleteFromTableTarifa(data.item.id)"
                      >
                        <feather-icon
                          icon="TrashIcon"
                          class="mr-50"
                        />
                        <span>Delete</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </span>
                  <span v-else>
                    <b-button
                      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                      variant="outline-primary"
                      class="btn-icon rounded-circle"
                      title="Aceptar"
                      @click="aceptar(data.item.id)"
                    >
                      <feather-icon icon="CheckIcon" />
                    </b-button>
                  </span>
                </span>
              </template>

              <!-- Optional default data cell scoped slot -->
              <template #cell()="data">
                {{ data.value }}
              </template>
            </BTable>
          </BCol>
        </BRow>
      </b-form>

      <!--   <b-card-text> qwe </b-card-text> -->
    </b-modal>
  </b-card>
</template>

<script>
import {
  BTable,
  BButton,
  BCol,
  BForm,
  BCard,
  BCardBody,
  BCardHeader,
  BMedia,
  BButtonGroup,
  BRow,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BAlert,
  BFormTextarea,
  BFormInput,
  BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import vSelect from 'vue-select'
import store from '@/store'
import { useNotify } from '@/helpers/toast'

export default {
  directives: {
    Ripple,
  },
  components: {
    BDropdownItem,
    BBadge,
    BDropdown,
    BButton,
    BCol,
    BForm,
    BCard,
    BCardBody,
    BCardHeader,
    BMedia,
    BButtonGroup,
    BRow,
    BFormGroup,
    BTable,
    BAlert,
    vSelect,
    BFormTextarea,
    BFormInput,
  },
  props: {
    idAtractivo: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      fields: [
        { key: 'tipo', label: 'Tipo' },
        {
          key: 'datos',
          label: 'Detalle',
          tdClass: ['text-center'],
          thClass: ['text-center'],
        },
        {
          key: 'Acciones',
          label: 'Acciones',
          tdClass: ['text-center', 'bg-white'],
          thClass: ['text-center'],
        },
      ],
      fieldsTarifa: [
        { key: 'segmento', label: 'Segmento' },
        { key: 'tarifa', label: 'Tarifa S/. ' },
        'Acciones',
      ],
      items: [],
      boletoSegmentos: [],
      tarifaList: [],
      optionsTipo: [
        { text: 'LIBRE', value: 1 },
        { text: 'BOLETO O TICKET', value: 2 },
        { text: 'SEMI RESTRINGIDO', value: 3 },
        { text: 'OTRO', value: 4 },
      ],
    }
  },
  mounted() {
    this.getTipoIngreso()
  },
  methods: {
    isBoleto(texto) {
      if (texto === 'BOLETO O TICKET') {
        return true
      }
      return false
    },
    closeModal() {
      this.clearFormIV()
      this.$refs.insertAsociacion.hide()
    },
    clean() {
      this.items = []
    },
    addTable() {
      this.$refs.insertAsociacion.hide()

      if (this.isEdit) {
        this.items[0].tipo = this.selectTipo.text
        this.items[0].datos = this.formIV.textarea
        if (this.selectTipo.value === 2) this.items[0].datos = this.tarifaList
      } else {
        const idTipoIngreso = -1
        const tipoIngresoObject = {
          id: idTipoIngreso,
          tipo: this.selectTipo.text,
          datos: this.formIV.textarea,
        }
        if (this.selectTipo.value === 2) tipoIngresoObject.datos = this.tarifaList
        this.items.push(tipoIngresoObject)
      }
    },
    editFromTable(item) {
      this.isEdit = true
      this.$refs.insertAsociacion.show()
      if (!item.tipo.includes('BOLETO')) {
        this.formIV = {
          textarea: item.datos[0],
        }
        const tipo = this.optionsTipo.find(x => x.text === item.tipo)
        this.selectTipo = tipo
      }
    },

    deleteFromTable(event) {
      const index = this.items.findIndex(item => item === event)
      this.items.splice(index, 1)
    },
    clearFormIV() {
      this.formIV = {
        nombre: '',
        representante: '',
        celular: '',
        email: '',
        web: '',
      }
    },
    onChangeTipo() {
      this.formIV.textarea = ''
      this.tarifaList = []
    },

    addTableTarifaList() {
      // this.$refs.insertTarifa.hide()
      const id = this.tarifaList.length + 1
      this.tarifaList.push({
        id,
        segmento: '',
        tarifa: '',
        editable: true,
      })
      // this.clearFormTarifa()
    },
    editTarifaTable(id) {
      /* find in this.tarifaList with index id */
      const index = this.tarifaList.findIndex(item => item.id === id)
      this.tarifaList[index].editable = true
    },
    deleteFromTableTarifa(event) {
      const index = this.tarifaList.findIndex(item => item.id === event)
      this.tarifaList.splice(index, 1)
    },
    aceptar(id) {
      const index = this.tarifaList.findIndex(item => item.id === id)
      this.tarifaList[index].editable = false
    },
    async getTipoIngreso() {
      this.items = []
      this.boletoSegmentos = []
      this.isBusy = true
      this.isReady = false
      await store
        .dispatch('plataforma/ATRACTIVO_GET_TIPO_INGRESO', {
          id: this.$props.idAtractivo,
        })
        .then(response => {
          if (response && response.length > 0) {
            const [one] = response
            this.items.push(one)
            if (one.tipo === 'BOLETO O TICKET') {
              this.selectTipo = { text: 'BOLETO O TICKET', value: 2 }
              let spliteListTarifa = []
              const info = this.items[0].datos.replace('FROMWOOCITY', '')
              if (info.includes('***')) {
                spliteListTarifa = info.split('***')
                spliteListTarifa.forEach(element => {
                  const [segmento, tarifa] = element.split('-')
                  const id = this.tarifaList.length + 1
                  this.tarifaList.push({
                    id,
                    segmento,
                    tarifa,
                    editable: false,
                  })
                })
              } else {
                const [segmento, tarifa] = info.split('-')
                const id = this.tarifaList.length + 1
                this.tarifaList.push(
                  { id,
                    segmento,
                    tarifa,
                    editable: false },
                )
              }
              this.items[0].datos = []
              const object = this.tarifaList
              this.items[0].datos = object
            }
          }
        })
        .catch(error => {
          let errorMessage = 'Intentelo en otro momento.'
          if (error.response) {
            errorMessage = error.response.data.message
          }
          this.notify('Ha ocurrido un error', errorMessage, 'danger')
        })
        .finally(() => {
          this.isBusy = false
          this.isReady = true
        })
    },
    async saveFormFourth() {
      this.isBusy = true
      const formData = ref({})
      formData.value.idAtractivo = this.$props.idAtractivo
      formData.value.datos = ''
      formData.value.idTipoIngreso = this.items[0].idTipoIngreso ?? -1
      formData.value.tipo = this.items[0].tipo.trim()
      formData.value.datos = this.items[0].datos
      if (this.items[0].tipo.includes('BOLETO')) {
        formData.value.datos = ''
        this.items[0].datos.forEach((tipo, index) => {
          if (index > 0) {
            formData.value.datos += '***'
          } else if (index === 0) {
            formData.value.datos = 'FROMWOOCITY'
          }
          formData.value.datos += `${tipo.segmento}-${tipo.tarifa}`
        })
      }
      await store
        .dispatch('plataforma/ATRACTIVO_CREATE_TIPO_INGRESO', formData.value)
        .then(async response => {
          this.notify('Operación Exitosa', response.message, 'success')
        })
        .catch(error => {
          let errorMessage = 'Intentelo en otro momento.'
          if (error.response) {
            errorMessage = error.response.data.message
          }
          this.notify('Ha ocurrido un error', errorMessage, 'danger')
        })
      this.isBusy = false
    },
  },
  setup(props, context) {
    const { notify } = useNotify(context)
    const formIV = ref({})
    const isBusy = ref(false)
    const isReady = ref(false)
    const selectTipo = ref(0)
    const isEdit = ref(true)

    return {
      isEdit,
      isBusy,
      formIV,
      selectTipo,
      notify,
      isReady,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.header-df {
  background-color: rgba(34, 41, 47, 0.03) !important;
  border-bottom: 1px solid rgba(34, 41, 47, 0.125) !important;
}

.nav-tabs {
  background: #f6f6f6 !important;
}

.actions-bar {
  background: #f6f6f6 !important;
  justify-content: end;
}

.form-group {
  margin-bottom: 0.5rem !important;
}

#seccion-content.card > .card-body {
  padding-top: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

#seccion-content {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
