<template>
  <b-card id="seccion-content">
    <b-card-header class="header-df">
      <b-col
        cols="12"
        md="6"
        class="pl-0"
      >
        <b-media vertical-align="center">
          <template #aside>
            <feather-icon
              size="36"
              icon="InfoIcon"
            />
          </template>
          <span class="font-weight-bold d-block text-nowrap">
            Datos Generales
          </span>
          <small class="text-muted">SECCIÓN I</small>
        </b-media>
      </b-col>
      <div class="float-right">
        <b-button-group size="sm">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="secondary"
            :to="{ name: 'rt-plataforma-info-atractivo' }"
          >
            <feather-icon
              class="mr-50"
              icon="ChevronLeftIcon"
            />
            <span class="align-middle">Atrás</span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            @click="saveForm"
          >
            <feather-icon
              class="mr-50"
              icon="SaveIcon"
            />
            <span class="align-middle">Guardar</span>
          </b-button>
        </b-button-group>
      </div>
    </b-card-header>
    <b-overlay
      :show="show"
      rounded="sm"
    >
      <b-card-body>
        <b-form class="pb-2 pl-0 pr-0 pt-1">
          <h6 class="text-dark">
            <feather-icon icon="ClipboardIcon" />
            <span class="align-middle ml-25">Ficha Básica</span>
          </h6>

          <hr
            style="border-top: 2px solid #161d31;margin-bottom: 1rem !important;margin-top: 0 !important;"
          >
          <b-row>
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                label="Destino Turístico"
                label-for="destino"
              >
                <v-select
                  id="destino"
                  v-model="destinoSel"
                  label="nombre"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="destinos"
                  :clearable="false"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="9"
            >
              <b-form-group
                label="Nombre Atractivo"
                label-for="nombre"
              >
                <b-form-input
                  id="nombre"
                  v-model="dataRegister.nombre"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label="Descripción"
                label-for="descripcion"
              >
                <quill-editor
                  id="descripcion"
                  v-model="dataRegister.descripcion"
                  :options="editorOption"
                  class="border-bottom-0"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label="Particularidad"
                label-for="particularidad"
              >
                <quill-editor
                  id="particularidad"
                  v-model="dataRegister.particularidad"
                  :options="editorOption"
                  class="border-bottom-0"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <h6 class="text-dark">
            <feather-icon icon="MapPinIcon" />
            <span class="align-middle ml-25">Datos de Ubicación</span>
          </h6>

          <hr
            style="border-top: 2px solid #161d31;margin-bottom: 1rem !important;margin-top: 0 !important;"
          >

          <b-row>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Departamento"
                label-for="dpto"
              >
                <v-select
                  v-model="dptoSel"
                  :reduce="m => m.idUbigeo"
                  label="descripcion"
                  :clearable="false"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="dptos"
                  @input="getUbigeos('prov', dptoSel.slice(0, 2))"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Provincia"
                label-for="prov"
              >
                <v-select
                  v-model="provSel"
                  :reduce="m => m.idUbigeo"
                  label="descripcion"
                  :clearable="false"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="provs"
                  @input="getUbigeos('dist', provSel.slice(0, 4))"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Distrito"
                label-for="dist"
              >
                <v-select
                  v-model="distSel"
                  :reduce="m => m.idUbigeo"
                  label="descripcion"
                  :clearable="false"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="dists"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Dirección"
                label-for="direccion"
              >
                <b-form-input
                  id="direccion"
                  v-model="dataRegister.direccion"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Referencia"
                label-for="referencia"
              >
                <b-form-input
                  id="referencia"
                  v-model="dataRegister.referencia"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <b-form-group
                label="Latitud"
                label-for="latitud"
              >
                <b-form-input
                  id="latitud"
                  v-model="dataRegister.latitud"
                  type="number"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <b-form-group
                label="Longitud"
                label-for="longitud"
              >
                <b-form-input
                  id="longitud"
                  v-model="dataRegister.longitud"
                  type="number"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <h6 class="text-dark">
            <feather-icon icon="BookmarkIcon" />
            <span class="align-middle ml-25">Categorización Mincetur</span>
          </h6>

          <hr
            style="border-top: 2px solid #161d31;margin-bottom: 1rem !important;margin-top: 0 !important;"
          >

          <b-row>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Categoría"
                label-for="categoria"
              >
                <v-select
                  v-model="categoriaRecursoSel"
                  :reduce="m => m.idCategoriaRecurso"
                  label="descripcion"
                  :clearable="false"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="categoriasRecurso"
                  @input="getTiposRecurso()"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Tipo de Recurso"
                label-for="tipoRecurso"
              >
                <v-select
                  v-model="tipoRecursoSel"
                  :reduce="m => m.idTipoRecurso"
                  label="descripcion"
                  :clearable="false"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="tiposRecurso"
                  @input="getSubTiposRecurso()"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Sub Tipo de Recurso"
                label-for="subTipoRecurso"
              >
                <v-select
                  v-model="subTipoRecursoSel"
                  :reduce="m => m.idSubTipoRecurso"
                  label="descripcion"
                  :clearable="false"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="subTiposRecurso"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <h6 class="text-dark">
            <feather-icon icon="StarIcon" />
            <span class="align-middle ml-25">Otras Clasificaciones</span>
          </h6>

          <hr
            style="border-top: 2px solid #161d31;margin-bottom: 1rem !important;margin-top: 0 !important;"
          >

          <b-row>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Interés al que responde"
                label-for="interes"
              >
                <v-select
                  v-model="interesSel"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  multiple
                  :options="intereses"
                  :reduce="m => m.idClasificacion"
                  label="nombre"
                >
                  <template #option="{ nombre, icono }">
                    <font-awesome-icon
                      :icon="icono || 'fa-solid fa-list'"
                    />
                    <span> {{ nombre }}</span>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Segmentos al que se adecua"
                label-for="segmentos"
              >
                <v-select
                  v-model="segmentoSel"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  multiple
                  :options="segmentos"
                  :reduce="m => m.idClasificacion"
                  label="nombre"
                >
                  <template #option="{ nombre, icono }">
                    <font-awesome-icon
                      :icon="icono || 'fa-solid fa-list'"
                    />
                    <span> {{ nombre }}</span>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Experiencias asociadas"
                label-for="experiencias"
              >
                <v-select
                  v-model="experienciaSel"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  multiple
                  :options="experiencias"
                  :reduce="m => m.idClasificacion"
                  label="nombre"
                >
                  <template #option="{ nombre, icono }">
                    <font-awesome-icon
                      :icon="icono || 'fa-solid fa-list'"
                    />
                    <span> {{ nombre }}</span>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
          </b-row>

          <h6 class="text-dark">
            <feather-icon icon="PhoneOutgoingIcon" />
            <span class="align-middle ml-25">Datos de Contacto y Canales Digitales</span>
          </h6>

          <hr
            style="border-top: 2px solid #161d31;margin-bottom: 1rem !important;margin-top: 0 !important;"
          >

          <b-row>
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                label="Teléfono"
                label-for="telefono"
              >
                <b-form-input
                  id="telefono"
                  v-model="dataRegister.telefono"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                label="Whatsapp"
                label-for="celular"
              >
                <b-form-input
                  id="celular"
                  v-model="dataRegister.celular"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                label="Email"
                label-for="email"
              >
                <b-form-input
                  id="email"
                  v-model="dataRegister.email"
                  type="email"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                label="Página Web"
                label-for="sitioWeb"
              >
                <b-form-input
                  id="sitioWeb"
                  v-model="dataRegister.sitioWeb"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                label="Facebook"
                label-for="facebook"
              >
                <b-form-input
                  id="facebook"
                  v-model="dataRedesSociales.facebook"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                label="Youtube"
                label-for="youtube"
              >
                <b-form-input
                  id="youtube"
                  v-model="dataRedesSociales.youtube"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                label="Instagram"
                label-for="instagram"
              >
                <b-form-input
                  id="instagram"
                  v-model="dataRedesSociales.instagram"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                label="Twitter"
                label-for="twitter"
              >
                <b-form-input
                  id="twitter"
                  v-model="dataRedesSociales.twitter"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-card-body>
    </b-overlay>
  </b-card>
</template>

<script>
import store from '@/store'
import {
  BButton,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BCard,
  BCardBody,
  BCardHeader,
  BMedia,
  BRow,
  BButtonGroup,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import { quillEditor } from 'vue-quill-editor'
import { useNotify } from '@/helpers/toast'
import vSelect from 'vue-select'

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BCard,
    BCardBody,
    BCardHeader,
    BMedia,
    BRow,
    BOverlay,
    vSelect,
    BButtonGroup,
    quillEditor,
  },
  props: {
    dataEdit: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {}
  },
  setup(props, context) {
    const { notify } = useNotify(context)
    const show = ref(true)
    const isBusy = ref(false)
    const destinoSel = ref({})
    const destinos = ref([])
    const dptoSel = ref('220000')
    const provSel = ref(null)
    const distSel = ref(null)
    const dptos = ref([])
    const provs = ref([])
    const dists = ref([])
    const dataRegister = ref(props.dataEdit)
    const categoriasRecurso = ref([])
    const categoriaRecursoSel = ref({})
    const tiposRecurso = ref([])
    const tipoRecursoSel = ref({})
    const subTiposRecurso = ref([])
    const subTipoRecursoSel = ref({})
    const intereses = ref([])
    const interesSel = ref([])
    const segmentos = ref([])
    const segmentoSel = ref([])
    const experiencias = ref([])
    const experienciaSel = ref([])
    const toolbarOptions = [
      ['bold', 'italic', 'underline', 'strike'],
      [{ align: [] }],
      ['blockquote'],
      [{ header: 1 }, { header: 2 }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'image'],
      // [{ script: 'sub' }, { script: 'super' }],
      [{ indent: '-1' }, { indent: '+1' }],
      [{ direction: 'rtl' }],
      // [{ size: ['small', false, 'large', 'huge'] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] }, { background: [] }],
      // [{ font: [] }],
      // ['clean'],
    ]
    const dataRedesSociales = ref({
      facebook: '',
      youtube: '',
      twitter: '',
      instagram: '',
    })

    const editorOption = {
      modules: {
        toolbar: toolbarOptions,
      },
      placeholder: 'Ingrese descripción',
    }

    const getDestinos = async () => {
      await store
        .dispatch('catalogo/DESTINO_FIND_ALL', {
          query: '',
          page: -1,
          limit: -1,
          sortBy: '',
        })
        .then(response => {
          if (response.items) {
            destinos.value = response.items
          }
        })
        .catch(error => {
          context.emit('error-data', error)
        })
    }

    const getUbigeos = async (type, codUbigeo) => {
      await store
        .dispatch('catalogo/UBIGEO_FIND_ALL', {
          get: type,
          codUbigeo,
        })
        .then(response => {
          if (type === 'dpto') {
            dptos.value = response
            provSel.value = null
            distSel.value = null
            provs.value = []
            dists.value = []
          }
          if (type === 'prov') {
            provs.value = response
            provSel.value = null
            distSel.value = null
            dists.value = []
          }
          if (type === 'dist') {
            dists.value = response
          }
        })
        .catch(error => {
          context.emit('error-data', error)
        })
    }

    const getCategoriasRecurso = async () => {
      await store
        .dispatch('catalogo/CATEGORIA_RECURSO_FIND_ALL', {
          query: '',
          page: 1,
          limit: 1000,
          sortBy: '',
        })
        .then(response => {
          if (response.items) {
            categoriasRecurso.value = response.items
            tipoRecursoSel.value = {}
            subTipoRecursoSel.value = {}
          }
        })
        .catch(error => {
          context.emit('error-data', error)
        })
    }

    const getTiposRecurso = async () => {
      await store
        .dispatch('catalogo/TIPO_RECURSO_FIND_ALL', {
          idCategoriaRecurso: categoriaRecursoSel.value,
          query: '',
          page: 1,
          limit: 1000,
          sortBy: '',
        })
        .then(response => {
          if (response.items) {
            tiposRecurso.value = response.items
            tipoRecursoSel.value = {}
            subTipoRecursoSel.value = {}
          }
        })
        .catch(error => {
          context.emit('error-data', error)
        })
    }

    const getSubTiposRecurso = async () => {
      await store
        .dispatch('catalogo/SUBTIPO_RECURSO_FIND_ALL', {
          idCategoriaRecurso: categoriaRecursoSel.value,
          idTipoRecurso: tipoRecursoSel.value,
          query: '',
          page: 1,
          limit: 1000,
          sortBy: '',
        })
        .then(response => {
          if (response.items) {
            subTiposRecurso.value = response.items
            subTipoRecursoSel.value = {}
          }
        })
        .catch(error => {
          context.emit('error-data', error)
        })
    }

    const getClasificaciones = async seccion => {
      await store.dispatch('catalogo/CLASIFICACION_FIND_ALL_BY_SECCION', {
        seccion,
      })
        .then(response => {
          if (seccion === 'INTERES_RECURSOS') {
            intereses.value = response.data
          }
          if (seccion === 'SEGMENTOS_RECURSOS') {
            segmentos.value = response.data
          }
          if (seccion === 'EXPERIENCIA_TURISTICA') {
            experiencias.value = response.data
          }
        })
    }

    const getClasificacionesByAtractivo = async () => {
      await store.dispatch('plataforma/ATRACTIVO_GET_CLASIFICACIONES', {
        idAtractivo: dataRegister.value.idAtractivo,
        seccion: '',
      })
        .then(response => {
          response.forEach(clas => {
            if (clas.seccion === 'INTERES_RECURSOS') {
              interesSel.value.push(clas.clasificacion.idClasificacion)
            }
            if (clas.seccion === 'SEGMENTOS_RECURSOS') {
              segmentoSel.value.push(clas.clasificacion.idClasificacion)
            }
            if (clas.seccion === 'EXPERIENCIA_TURISTICA') {
              experienciaSel.value.push(clas.clasificacion.idClasificacion)
            }
          })
        })
    }

    const saveForm = async () => {
      if (destinoSel.value === null) {
        context.emit('error-data', { response: { data: { message: 'Debe seleccionar Destino' } } })
        return
      }

      if (dataRegister.value.nombre === undefined) {
        context.emit('error-data', { title: 'Ha ocurrido un error', message: 'Debe ingresar nombre del atractivo', type: 'danger' })
        return
      }

      if (dataRegister.value.descripcion === undefined) {
        context.emit('error-data', { title: 'Ha ocurrido un error', message: 'Debe ingresar descripción del atractivo', type: 'danger' })
        return
      }

      if (dataRegister.value.direccion === undefined) {
        context.emit('error-data', { title: 'Ha ocurrido un error', message: 'Debe ingresar direccion del atractivo', type: 'danger' })
        return
      }

      if (dptoSel.value === null) {
        context.emit('error-data', { response: { data: { message: 'Debe seleccionar departamento' } } })
        return
      }

      if (provSel.value === null) {
        context.emit('error-data', { response: { data: { message: 'Debe seleccionar provincia' } } })
        return
      }

      if (distSel.value === null) {
        context.emit('error-data', { response: { data: { message: 'Debe seleccionar distrito' } } })
        return
      }

      if (categoriaRecursoSel.value === null) {
        context.emit('error-data', { response: { data: { message: 'Debe seleccionar categoría del recurso' } } })
        return
      }

      if (tipoRecursoSel.value === null) {
        context.emit('error-data', { response: { data: { message: 'Debe seleccionar tipo del recurso' } } })
        return
      }

      if (subTipoRecursoSel.value === null) {
        context.emit('error-data', { response: { data: { message: 'Debe seleccionar sub tipo del recurso' } } })
        return
      }

      show.value = true
      // Validar datos antes
      const service = 'plataforma/ATRACTIVO_UPDATE'
      dataRegister.value.destino = destinoSel.value
      dataRegister.value.ubigeo = {
        idUbigeo: distSel.value,
      }
      dataRegister.value.idCategoriaRecurso = categoriaRecursoSel.value
      dataRegister.value.idTipoRecurso = tipoRecursoSel.value
      dataRegister.value.idSubTipoRecurso = subTipoRecursoSel.value
      dataRegister.value.redesSociales = `Facebook**${dataRedesSociales.value.facebook}###Youtube**${dataRedesSociales.value.youtube}###Twitter**${dataRedesSociales.value.twitter}###Instagram**${dataRedesSociales.value.instagram}`
      await store.dispatch(service, dataRegister.value)
        .then(async response => {
          // Experiencias Turisticas
          await store.dispatch('plataforma/ATRACTIVO_CHECK_CLASIFICACION', {
            idAtractivo: dataRegister.value.idAtractivo,
            idsClasificacion: experienciaSel.value.join(','),
            seccion: 'EXPERIENCIA_TURISTICA',
          })
          await store.dispatch('plataforma/ATRACTIVO_CHECK_CLASIFICACION', {
            idAtractivo: dataRegister.value.idAtractivo,
            idsClasificacion: segmentoSel.value.join(','),
            seccion: 'SEGMENTOS_RECURSOS',
          })
          await store.dispatch('plataforma/ATRACTIVO_CHECK_CLASIFICACION', {
            idAtractivo: dataRegister.value.idAtractivo,
            idsClasificacion: interesSel.value.join(','),
            seccion: 'INTERES_RECURSOS',
          })
          notify('Operación Exitosa', response.message, 'success')
        })
        .catch(error => {
          context.emit('error-data', error)
        })
      show.value = false
    }

    const getRedesSociales = dataRS => {
      if (dataRS !== null && dataRS.length > 0) {
        if (dataRS.split('###').length > 1) {
          if (dataRS.split('###')[0].split('**')[1].length > 5) {
            // eslint-disable-next-line prefer-destructuring
            dataRedesSociales.value.facebook = dataRS.split('###')[0].split('**')[1]
          }
          if (dataRS.split('###')[1].split('**')[1].length > 5) {
            // eslint-disable-next-line prefer-destructuring
            dataRedesSociales.value.youtube = dataRS.split('###')[1].split('**')[1]
          }
          if (dataRS.split('###')[2].split('**')[1].length > 5) {
            // eslint-disable-next-line prefer-destructuring
            dataRedesSociales.value.twitter = dataRS.split('###')[2].split('**')[1]
          }
          if (dataRS.split('###')[3].split('**')[1].length > 5) {
            // eslint-disable-next-line prefer-destructuring
            dataRedesSociales.value.instagram = dataRS.split('###')[3].split('**')[1]
          }
        }
      } else {
        dataRedesSociales.value.facebook = ''
        dataRedesSociales.value.youtube = ''
        dataRedesSociales.value.twitter = ''
        dataRedesSociales.value.instagram = ''
      }
    }

    const resetForm = () => {
      dataRegister.value = {}
      dataRedesSociales.value = {
        facebook: '',
        youtube: '',
        twitter: '',
        instagram: '',
      }
      destinoSel.value = {}
    }

    const loadData = async () => {
      show.value = true
      resetForm()
      dataRegister.value = props.dataEdit
      getRedesSociales(dataRegister.value.redesSociales)
      await getDestinos()
      dptoSel.value = `${dataRegister.value.ubigeo.idUbigeo.slice(0, 2)}0000`
      await getUbigeos('dpto', '')
      await getUbigeos('prov', dataRegister.value.ubigeo.idUbigeo.slice(0, 2))
      provSel.value = `${dataRegister.value.ubigeo.idUbigeo.slice(0, 4)}00`
      await getUbigeos('dist', dataRegister.value.ubigeo.idUbigeo.slice(0, 4))
      distSel.value = dataRegister.value.ubigeo.idUbigeo
      await getCategoriasRecurso()
      destinoSel.value = dataRegister.value.destino
      await getCategoriasRecurso()
      categoriaRecursoSel.value = dataRegister.value.idCategoriaRecurso
      await getTiposRecurso()
      tipoRecursoSel.value = dataRegister.value.idTipoRecurso
      await getSubTiposRecurso()
      subTipoRecursoSel.value = dataRegister.value.idSubtipoRecurso
      await getClasificaciones('INTERES_RECURSOS')
      await getClasificaciones('SEGMENTOS_RECURSOS')
      await getClasificaciones('EXPERIENCIA_TURISTICA')
      await getClasificacionesByAtractivo()
      show.value = false
    }

    loadData()

    return {
      show,
      isBusy,
      dataRegister,
      getDestinos,
      destinos,
      destinoSel,
      dptoSel,
      provSel,
      distSel,
      dptos,
      provs,
      dists,
      getUbigeos,
      getTiposRecurso,
      getSubTiposRecurso,
      saveForm,
      editorOption,
      categoriasRecurso,
      categoriaRecursoSel,
      tiposRecurso,
      tipoRecursoSel,
      subTiposRecurso,
      subTipoRecursoSel,
      intereses,
      interesSel,
      segmentos,
      segmentoSel,
      experiencias,
      experienciaSel,
      dataRedesSociales,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.header-df {
  background-color: rgba(34, 41, 47, 0.03) !important;
  border-bottom: 1px solid rgba(34, 41, 47, 0.125) !important;
}

.nav-tabs {
  background: #f6f6f6 !important;
}

.actions-bar {
  background: #f6f6f6 !important;
  justify-content: end;
}

.form-group {
  margin-bottom: 0.5rem !important;
}

#seccion-content.card .b-overlay-wrap .card-body {
  padding-top: 0px !important;
}

#seccion-content {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
